import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { vorlagenInUse } from "../../helpers/clientSide/constants";
import VorlagenPicker from "../03-organisms/VorlagenPicker/VorlagenPicker";

const VorlagenGenerator = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <meta name="description" content={t("vorlagen.pickerMeta")} />

        <title>{t("vorlagen.pickerTitle")}</title>
      </Helmet>

      <VorlagenPicker
        standalone={true}
        {...{ allAvailableVorlagen: vorlagenInUse }}
      />
    </div>
  );
};

export default VorlagenGenerator;
